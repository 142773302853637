.ant-card-body{
  padding-top: 0px !important;
}

.customCard__card {
  min-height: 230px;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 1px 5px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  transition: all 0.25s ease-in-out;
  margin-bottom: 20px;
}

.customCard__card .ant-card-body {
  text-align: center;
}

.customCard__card p {
  font-size: 1.5em;
  margin-top: 8px;
  margin-bottom: 8px;
}

.customCard__icon {
  font-size: 40px;
  color: #fff;
  background-color: var(--primary);
  border-radius: 50%;
  padding: 10px;
}

.customCard__card:hover {
  /* background-color: var(--primary); */
}

.customCard__card:hover p {
  color: black !important;
  font-weight: 600;
}

.customCard__card:hover .customCard__icon {
  background-color: #fff;
  color: var(--primary);
}

.customCard__image {
  border-radius: 4px;
}