.site-layout-content {
  background: #f0f0f0;
  padding-bottom: 20px;
}

.logo {
  height: 64px;
  cursor: pointer;
  position: relative;
}

.logo img {
  height: 100%;
}

.pageLayout__title {
  color: #2592cf;
  font-size: 18px;
  font-weight: 700;
}

.pageLayout__header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  position: sticky;
  z-index: 1;
  top: 0;
}

.pageLayout__footer {
  margin-top: 30px;
  background-color: #f0f0f0;
  /* box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), */
  /* 0 1px 5px 0 rgba(0, 0, 0, 0.06); */
  box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2);
}

.pageLayout__copyright {
  text-align: center;
}
