.clients__title {
  color: #17313b;
  display: block;
  font-family: Roboto,sans-serif;
  font-size: 24px;
  line-height: 28px;
  margin-left: 90px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.clients__list {
  display: flex;
  margin: 40px 0;
}

.clients__img {
  height: 80px;
  margin: 0 20px;
}

.clients__image {
  padding: 20px;
  border-radius: 8px;
  margin: 0 10px;
  transition: all 0.3s ease-in-out;
}
